var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-item-dropdown',{staticClass:"dropdown-notification mr-25",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body",attrs:{"badge":_vm.notificationCount,"badge-classes":"bg-danger","icon":"BellIcon","size":"21"}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v(" "+_vm._s(_vm.$t("notifications_title"))+" ")]),(_vm.notificationCount)?_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.notificationCount)+" "+_vm._s(_vm.$t("new"))+" ")]):_vm._e()],1),_c('overlay-loading',{attrs:{"isLoading":_vm.overlayLoading}})],1),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"}},_vm._l((_vm.$store.state.notifications.notifications),function(notification){return _c('div',{key:notification.id},[(notification.data)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.redirectUser(
            notification.data.type,
            notification.data.id,
            notification.data.catalogId,
            notification.id
          )}}},[(notification.data.title)?_c('p',{staticClass:"media-heading d-flex justify-content-between flex-wrap"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(notification.data.title)+" ")]),_c('span',{staticClass:"notification-text"},[_vm._v(" "+_vm._s(_vm.formattedDate(notification.created_at))+" ")])]):_vm._e(),(notification.data.body)?_c('small',{staticClass:"notification-text"},[_vm._v(" "+_vm._s(notification.data.body)+" ")]):_vm._e()]):_vm._e(),_c('b-dropdown-divider')],1)}),0),_c('li',{staticClass:"dropdown-menu-footer"},[(
        _vm.globalPermission['notifications'] &&
        _vm.globalPermission['notifications']['mark-all-as-read']
      )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.readNotification()}}},[_vm._v(" "+_vm._s(_vm.$t("read_all_notifications"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }