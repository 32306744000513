<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("notifications_title") }}
        </h4>
        <b-badge pill variant="light-primary" v-if="notificationCount">
          {{ notificationCount }} {{ $t("new") }}
        </b-badge>
      </div>
      <overlay-loading :isLoading="overlayLoading" />
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <div
        v-for="notification in $store.state.notifications.notifications"
        :key="notification.id"
      >
        <b-dropdown-item
          v-if="notification.data"
          @click="
            redirectUser(
              notification.data.type,
              notification.data.id,
              notification.data.catalogId,
              notification.id
            )
          "
        >
          <p
            class="media-heading d-flex justify-content-between flex-wrap"
            v-if="notification.data.title"
          >
            <span class="font-weight-bolder">
              {{ notification.data.title }}
            </span>
            <span class="notification-text">
              {{ formattedDate(notification.created_at) }}
            </span>
          </p>
          <small class="notification-text" v-if="notification.data.body">
            {{ notification.data.body }}
          </small>
        </b-dropdown-item>
        <b-dropdown-divider />
      </div>
    </vue-perfect-scrollbar>

    <!-- Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-if="
          globalPermission['notifications'] &&
          globalPermission['notifications']['mark-all-as-read']
        "
        @click="readNotification()"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        {{ $t("read_all_notifications") }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { formatDistance } from "date-fns";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import OverlayLoading from "@/components/shared/OverlayComponent";
import { en, ar } from "date-fns/locale";

export default {
  name: "NotificationsComponent",
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BDropdownDivider,
    OverlayLoading,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      notificationsData: "getNotifications",
      notificationCount: "getNotificationsCount",
    }),
  },
  data() {
    return {
      formatDistance,
      overlayLoading: false,
    };
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
  methods: {
    async readNotification() {
      this.overlayLoading = true;
      await this.$store.dispatch("read_all_notifications");
      this.overlayLoading = false;
    },
    redirectUser(type, id, catalogId, notificationId) {
      if (
        this.globalPermission["notifications"] &&
        this.globalPermission["notifications"].show
      ) {
        if (type && id) {
          const notificationTypes = {
            product: `/show-product/${catalogId}/${id}`,
            order: `/show-order/${id}`,
          };
          this.$store.dispatch("read_notification", notificationId);
          this.$route.path !== notificationTypes[type] &&
            this.$router.push(notificationTypes[type]);
        }
      }
    },
    formattedDate(date) {
      return formatDistance(new Date(date), new Date(), {
        addSuffix: true,
        locale: localStorage.getItem("lang") === "ar" ? ar : en,
      });
    },
  },
};
</script>

<style></style>
