export default [
  {
    title: "g.store",
    route: "home",
    icon: "categories.svg",
    iconActive: "categories-fill.svg",
    modulePermission: { vendor: { profile: true } },
  },
  {
    title: "g.catalog",
    route: "CatalogList",
    icon: "catalog.svg",
    iconActive: "catalog-fill.svg",
    modulePermission: {
      catalogs: { index: true },
      brands: { index: true },
      categories: { index: true },
    },
  },
  {
    title: "g.products",
    route: "products",
    icon: "store.svg",
    iconActive: "store-fill.svg",
    modulePermission: {
      catalogs: { index: true },
      products: {
        index: true,
      },
    },
  },
  {
    title: "orders.orders",
    route: "orders-list",
    icon: "order.svg",
    iconActive: "order-fill.svg",
    modulePermission: { orders: { index: true } },
  },
  {
    title: "g.users",
    route: "users",
    icon: "u-p.svg",
    iconActive: "u-p-fill.svg",
    modulePermission: { vendor: { myVendors: true } },
  },

  // ========  I need this ========
  // {
  //   title: "g.roles",
  //   route: "list-roles",
  //   icon: "roles.svg",
  //   iconActive: "roles-fill.svg",
  // },
];
